import React from 'react'
import ReactPlayer from 'react-player'
// Components
import Wrapper from '../wrapper'

const VideoCol1 = ({ content }) => {
  return (
    <Wrapper grid>
      <div className="video__col_1">
        <div className="player-wrapper">
          <ReactPlayer
            className='react-player'
            url={content.url}
            controls={true}
            width='100%'
            height='100%'
            config={{
              vimeo: {
                playerOptions: {
                  color: 'FFFF00',
                  title: false,
                  portrait: false,
                  byline: false,
                }
              },
            }}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default VideoCol1
