import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import { utilsService } from "../services/utils-service";

const Thumbnail = ({ project }) => {
  return (
    <Link to={`${utilsService.generatePrefix(project.locale)}/project/${project.slug}`}>
      <figure className="thumbnail__wrapper">
        <GatsbyImage
          alt={`${project.clientName}-${project.headline}`}
          image={project.thumbnail.gatsbyImageData}
          imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
        />
        <figcaption className="thumbnail__content__desktop">
          <h3
            className="white"
            dangerouslySetInnerHTML={{__html: project.headline}}
          />
          <h2>
            <span className="dash white"></span>
            {project.clientName}
          </h2>
        </figcaption>
      </figure>
      <figcaption className="thumbnail__content">
        <h3 dangerouslySetInnerHTML={{__html: project.headline}}/>
        <h2>
          <span className="dash"></span>
          {project.clientName}
        </h2>
      </figcaption>
    </Link>
  )
}

export default Thumbnail
