import React from 'react'
import ReactPlayer from "react-player";
// Components
import Wrapper from '../wrapper'

const VideoFullwidth = ({ content }) => (
  <Wrapper>
    <div className="video__fullwidth">
      <div className="player-wrapper">
        <ReactPlayer
          className='react-player'
          url={content.url}
          controls={true}
          width='100%'
          height='100%'
          config={{
            vimeo: {
              playerOptions:{
                title: false,
                portrait: false,
                byline: false,
                color: 'FFFF00',
              }
            },
          }}
        />
      </div>
    </div>
  </Wrapper>
)

export default VideoFullwidth
