import React from 'react'

const ProjectHero = ({ content, filters }) => (
  <div className="project__hero row no-gutters">
    <div className="project__client col-12">
      <h2>{content.clientName}</h2>
    </div>
    <div className="project__hero__description col-12 row no-gutters">
      <div className="col-xl-4
                      col-lg-5">
        <h1 className="small" dangerouslySetInnerHTML={{__html: content.headline}} />
      </div>
      <div className="col-xl-6 offset-xl-2
                      col-lg-6 offset-lg-1">
        <section className="paragraph" dangerouslySetInnerHTML={{__html: content.paragraph}} />
      </div>
    </div>
    <div className="filters row no-gutters col-12">
      <div className="project__hero__industries col-lg-4">
        <h2>{filters[0].name}</h2>
          <ul>
            {content.industries.map((item, index) => (
              <li key={`industry-${index}`}>
                <span className="paragraph">
                  {content.industries.length > index + 1 ?
                    `${item.name},` : `${item.name}.`}
                </span>
              </li>
            ))}
          </ul>
      </div>
      <div className="col-lg-6 offset-lg-2">
        <h2>{filters[1].name}</h2>
        <ul>
          {content.skills.map((item, index) => (
            <li key={`skill-${index}`}>
              <span className="paragraph">
                {content.skills.length > index + 1 ?
                  `${item.name},` : `${item.name}.`}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)

export default ProjectHero
