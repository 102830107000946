import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Wrapper from '../wrapper'

const ImageCol2 = ({ ...props }) => (
  <Wrapper grid>
    {props.content &&
    <div className="image__col_2
                    row no-gutters no-padding col-12">
        {props.content.map((item, index) => (
          <div key={`col-2-${index}`}
               className={`ratio image__col
                           ${props.square ? 'square': ''}
                           ${props.vertical ? 'vertical': ''}
                           col-12
                           col-md-6
                           col-lg-6
                           col-xl-6`}
          >
            <figure>
              <GatsbyImage className={'asset'} alt={item.alt || ''} image={item.gatsbyImageData} imgStyle={{ objectFit: 'cover', objectPosition: 'center' }} />
            </figure>
          </div>
        ))}
    </div>}
  </Wrapper>
)

export default ImageCol2
