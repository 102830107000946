import React from 'react'
import { graphql } from 'gatsby'

import {utilsService} from "../services/utils-service";

// Components
import Layout from '../layouts/index'
import Seo from '../components/seo'
import Wrapper from '../components/wrapper'
import ProjectHero from '../components/project/project_hero'
import ProjectCase from '../components/project/project_case'
import ProjectRelated from '../components/project/project_related'


const Project = ({ data, path, pageContext }) => {
  return (
    <Layout path={path} headerWhite={pageContext.headerWhite} locale={pageContext.locale} absolutePath={pageContext.absolutePath}>
      <Seo
        slug={`project/${data.datoCmsProject.slug}`}
        content={data.datoCmsProject.seo}
        lang={pageContext.locale}
        publication={data.datoCmsProject.meta}
        keywords={data.datoCmsProject.skills.map((item) => item.name).join(', ')}
      />
      <React.Fragment>
        <Wrapper grid>
          <ProjectHero content={data.datoCmsProject}
                        filters={data.allDatoCmsWhatWeDo.edges[0].node.filters} />
        </Wrapper>
        <Wrapper>
          <ProjectCase blocks={data.datoCmsProject.caseStudy} />
        </Wrapper>
        {data.datoCmsProject.relatedProjects.length > 0 ?
          <Wrapper color="black">
            <ProjectRelated
              related={data.allDatoCmsWhatWeDo.edges[0].node.relatedText}
              projects={data.datoCmsProject.relatedProjects}
            />
          </Wrapper>
          : null
        }
      </React.Fragment>
    </Layout>
  )
};

export default Project
export const query = graphql`
  query ProjectQuery($slug: String!, $locale: String!) {
    datoCmsProject(locale: {eq: $locale}, slug: { eq: $slug }) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      seo {
        title
        description
        image {
          url
          height
          width
        }
        twitterCard
      }
      headline
      clientName
      paragraph
      slug
      locale
      relatedProjects {
        id
        slug
        headline
        clientName
        slug
        locale
        thumbnail {
          alt
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      skills {
        id
        name
      }
      industries {
        id
        name
      }
      caseStudy {
        ... on DatoCmsImageFullwidth {
          model {
            apiKey
          }
          fullwidth {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsImageCol1 {
          model {
            apiKey
          }
          imageCol1 {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsImageCol1Sq {
          model {
            apiKey
          }
          imageCol1Sq {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsImageCol1Rect {
          model {
            apiKey
          }
          imageCol1Rect {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsImageCol2H {
          model {
            apiKey
          }
          imageCol2H {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsImageCol2Sq {
          model {
            apiKey
          }
          imageCol2Sq {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsImageCol2V {
          model {
            apiKey
          }
          imageCol2V {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsImageCol3H {
          model {
            apiKey
          }
          imageCol3H {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsImageCol3Sq {
          model {
            apiKey
          }
          imageCol3Sq {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsImageCol3V {
          model {
            apiKey
          }
          imageCol3V {
            alt
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        ... on DatoCmsTextCol1Right {
          model {
            apiKey
          }
          title
          textLink
          link
          textCol1Right
        }
        ... on DatoCmsTextCol1Left {
          model {
            apiKey
          }
          title
          textLink
          link
          textCol1Left
        }
        ... on DatoCmsTextCol2 {
          model {
            apiKey
          }
          textLink
          link
          textCol1
          textCol2
        }
        ... on DatoCmsTextQuote {
          model {
            apiKey
          }
          paragraph
          who
        }
        ... on DatoCmsVideoFullwidth {
          model{
            apiKey
          }
          videoFullwidth {
            url
            title
            width
            height
            provider
            providerUid
            thumbnailUrl
          }
        }
        ... on DatoCmsVideoCol1 {
          model{
            apiKey
          }
          videoCol1 {
            url
            title
            width
            height
            provider
            providerUid
            thumbnailUrl
          }
        }
      }
    }
    allDatoCmsWhatWeDo(filter: {
      locale: { eq: $locale }
    }) {
      edges {
        node {
          relatedText
          filters {
            id
            name
          }
        }
      }
    }
  }
`
