import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Wrapper from '../wrapper'

const ImageFullwidth = ({ content }) => (
  <Wrapper>
    {content.gatsbyImageData &&
      <figure>
        <GatsbyImage className={'image__fullwidth'} alt={content.alt || ''} image={content.gatsbyImageData} imgStyle={{ objectFit: 'cover', objectPosition: 'center' }} />
      </figure>}
  </Wrapper>
)

export default ImageFullwidth
