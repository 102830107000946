import React from 'react'

// Components
import Wrapper from '../wrapper'
import Thumbnail from '../thumbnail'

const ProjectRelated = ({ projects, related }) => (
  <Wrapper grid device_no_spacing>
    <div className="project__related row no-gutters">
      <div className="project__related__heading
                      row no-gutters col-12">
        <h2 className="white">
          <span className="dash white"></span>{related}
        </h2>
      </div>
      <div className="project__related__thumbnails
                      row no-gutters col-12"
      >
        {projects.map((item, index) => (
          <div key={`related-project-${index}`}
               className="related
                          col-12
                          col-md-6
                          col-lg-4
                          col-xl-4"
          >
            <Thumbnail project={item} />
          </div>
        ))}
      </div>
    </div>
  </Wrapper>
)

export default ProjectRelated
