import React from 'react'

// Components
import Wrapper from '../wrapper'
import Seemore from '../seemore'

const TextCol1Right = ({ content }) => (
  <Wrapper grid>
    <div className="text_cols justify-content-end
                    row col-12"
    >
      <div className="text_col_1_right
                      col-12
                      col-md-6
                      col-lg-6
                      col-xl-6"
      >
        {content.title &&
          <p className="title paragraph bold">
            {content.title}
          </p>}

        {content.textCol1Right &&
          <section className="paragraph"
                   dangerouslySetInnerHTML={{__html: content.textCol1Right}} />}

        {content.link &&
          <Seemore content={content} rotated external />}

      </div>

    </div>
  </Wrapper>
)

export default TextCol1Right
