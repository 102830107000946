import React from 'react'

// Components
import Wrapper from '../wrapper'
import ImageFullwidth from './image_fullwidth'
import ImageCol1 from './image_col_1'
import ImageCol2 from './image_col_2'
import ImageCol3 from './image_col_3'
import VideoFullwidth from './video_fullwidth'
import VideoCol1 from './video_col_1'
import TextQuote from './text_quote'
import TextCol1Right from './text_col_1_right'
import TextCol1Left from './text_col_1_left'
import TextCol2 from './text_col_2'

const IMAGE_FULLWIDTH     = 'image_fullwidth',
      IMAGE_COL_1         = 'image_col_1',
      IMAGE_COL_1_SQ      = 'image_col_1_sq',
      IMAGE_COL_1_RECT    = 'image_col_1_rect',
      IMAGE_COL_2_H       = 'image_col_2_h',
      IMAGE_COL_2_SQ      = 'image_col_2_sq',
      IMAGE_COL_2_V       = 'image_col_2_v',
      IMAGE_COL_3_H       = 'image_col_3_h',
      IMAGE_COL_3_SQ      = 'image_col_3_sq',
      IMAGE_COL_3_V       = 'image_col_3_v',
      VIDEO_FULLWIDTH     = 'video_fullwidth',
      VIDEO_COL_1         = 'video_col_1',
      TEXT_QUOTE          = 'text_quote',
      TEXT_COL_1_RIGHT    = 'text_col_1_right',
      TEXT_COL_1_LEFT     = 'text_col_1_left',
      TEXT_COL_2          = 'text_col_2'

const _renderCaseStudy = (blocks) => {
  return blocks.map((item, index) => {
    switch (item.model.apiKey) {
      case IMAGE_FULLWIDTH:
        return ( <ImageFullwidth key={`image-fullwidth-${index}`}
                                 content={item.fullwidth} /> )
      case IMAGE_COL_1:
        return ( <ImageCol1 key={`col-image-1-${index}`}
                            content={item.imageCol1} /> )
      case IMAGE_COL_1_SQ:
        return ( <ImageCol1 key={`col-image-1-sq-${index}`}
                            content={item.imageCol1Sq} square /> )
      case IMAGE_COL_1_RECT:
        return ( <ImageCol1 key={`col-image-1-rect-${index}`}
                            content={item.imageCol1Rect} rect /> )
      case IMAGE_COL_2_H:
        return ( <ImageCol2 key={`col-image-2-${index}`}
                            content={item.imageCol2H} /> )
      case IMAGE_COL_2_SQ:
        return ( <ImageCol2 key={`col-image-2-sq-${index}`}
                            content={item.imageCol2Sq} square /> )
      case IMAGE_COL_2_V:
        return ( <ImageCol2 key={`col-image-2-v-${index}`}
                            content={item.imageCol2V} vertical /> )
      case IMAGE_COL_3_H:
        return ( <ImageCol3 key={`col-image-3-h-${index}`}
                            content={item.imageCol3H} /> )
      case IMAGE_COL_3_SQ:
        return ( <ImageCol3 key={`col-image-3-sq-${index}`}
                            content={item.imageCol3Sq} square /> )
      case IMAGE_COL_3_V:
        return ( <ImageCol3 key={`col-image-3-v-${index}`}
                            content={item.imageCol3V} vertical /> )
      case VIDEO_FULLWIDTH:
        return ( <VideoFullwidth key={`video-fullwidth-${index}`}
                                 content={item.videoFullwidth} /> )
      case VIDEO_COL_1:
        return ( <VideoCol1 key={`video-col-1-${index}`}
                            content={item.videoCol1} /> )
      case TEXT_QUOTE:
        return ( <TextQuote key={`text-quote-${index}`}
                            content={item} /> )
      case TEXT_COL_1_RIGHT:
        return ( <TextCol1Right key={`text-col-1-right-${index}`}
                                content={item} /> )
      case TEXT_COL_1_LEFT:
        return ( <TextCol1Left key={`text-col-1-left-${index}`}
                               content={item} /> )
      case TEXT_COL_2:
        return ( <TextCol2 key={`text-col-2-${index}`}
                           content={item} /> )
      default:
        return null
    }
  })
}

const ProjectCase = ({ blocks }) => (
  <Wrapper>
    {_renderCaseStudy(blocks)}
  </Wrapper>
)

export default ProjectCase
