import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';

// Components
import Wrapper from '../wrapper'

const ImageCol3 = ({ ...props }) => (
  <Wrapper grid>
    {props.content &&
    <div className="image__col_3
                    row no-gutters">
      {props.content.map((item, index) => (
        <div key={`ratio-${index}`}
             className={`ratio image__col
                        ${props.square ? 'square': ''}
                        ${props.vertical ? 'vertical': ''}
                        col-12
                        col-md-4
                        col-lg-4
                        col-xl-4`}
        >
          <figure>
            <GatsbyImage className={'asset'} alt={item.alt || ''} image={item.gatsbyImageData} imgStyle={{ objectFit: 'cover', objectPosition: 'center' }} />
          </figure>
        </div>
      ))}
    </div>}
  </Wrapper>
)

export default ImageCol3
