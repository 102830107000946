import React from 'react'

// Components
import Wrapper from '../wrapper'
import Seemore from '../seemore'

const TextCol2 = ({ content }) => (
  <Wrapper grid>
    <div className="text_cols justify-content-between
                    row col-12"
    >
      <div className="text_col_2
                      col-12
                      col-md-6
                      col-lg-6
                      col-xl-6">
      {content.title &&
        <p className="title paragraph bold">
          {content.title}
        </p>}

      {content.textCol1 &&
        <section className="paragraph"
                 dangerouslySetInnerHTML={{__html: content.textCol1}} />}

      {content.link &&
        <Seemore content={content} rotated external />}

      </div>
      <div className="text_col_2
                      col-12
                      col-md-6
                      col-lg-6
                      col-xl-6">
      {content.textCol2 &&
        <section className="paragraph"
                 dangerouslySetInnerHTML={{__html: content.textCol2}} />}
      </div>
    </div>
  </Wrapper>
)

export default TextCol2
