import React from 'react'

// Components
import Wrapper from '../wrapper'

const TextQuote = ({ content }) => (
  <Wrapper grid>
    {content.paragraph &&
      <div className="text_cols justify-content-center
                      row no-gutters no-padding col-12"
      >
        <div className="text_quote
                        col-12
                        col-sm-11
                        col-xl-10"
        >
          <h3 dangerouslySetInnerHTML={{__html: content.paragraph}} />
          <h2>{content.who}</h2>
        </div>
      </div>
    }
  </Wrapper>
)

export default TextQuote
