import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';

// Components
import Wrapper from '../wrapper'

const ImageCol1 = ({ ...props }) => (
  <Wrapper grid>
    {props.content.gatsbyImageData &&
      <div className={`image__col image__col_1
                      ${props.square ? 'square': ''}
                      ${props.rect ? 'rect': ''}`}>
        <figure>
          <GatsbyImage className={'asset'} alt={props.content.alt || ''} image={props.content.gatsbyImageData} imgStyle={{ objectFit: 'cover', objectPosition: 'center' }} />
        </figure>
      </div>}
  </Wrapper>
)

export default ImageCol1
